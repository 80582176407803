import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper";

export default function SliderSwiper({ children, searchComponent }) {
	const swiperRef = useRef(null);

	return (
		<div
			className="slider-container"
			onMouseEnter={() => swiperRef.current?.autoplay?.start()}
			onMouseLeave={() => swiperRef.current?.autoplay?.stop()}
		>
			<Swiper
				ref={swiperRef}
				modules={[Autoplay, Pagination]}
				slidesPerView={1}
				spaceBetween={5}
				loop={true}
				pagination={{ clickable: true }}
				autoplay={{ delay: 3000, disableOnInteraction: false }}
			>
				{children.map((child, index) => (
					<SwiperSlide key={index}>{child}</SwiperSlide>
				))}
			</Swiper>

			{/* Centered Search Component */}
			<div className="search-container">{searchComponent}</div>
		</div>
	);
}
