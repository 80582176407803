import React, { useState, Fragment, useEffect } from "react";
import styles from "./footer.module.scss";
import { Container, Grid } from "@material-ui/core";
import logo from "../../img/logo-alfuttaim-footer.png";
import logoSki from "../../img/logo.svg";
import blueArrow from "../../img/blueArrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";
// import tiktok from '../../img/tik-tok.svg'
import useLocale from "../../lang/useLocale";
import { Link } from "react-router-dom";
import Popup from "../General/popup/popup";
import { useSelector } from "react-redux";
import Rules from "./rules/rules";
import PopupTab from "../General/popup/popUpTab";
import { useForm } from "react-hook-form";
// import { useHistory } from 'react-router-dom';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import axios from "axios";
import loaderImg from "../../img/loader.gif";
import flakeIcon from "../../img/flake-icon.png";
function Footer() {
  const { getLocale } = useLocale();
  const lang = useSelector((state) => state.lang);
  const [explore, setExplore] = useState(false);
  const [links, setLinks] = useState(false);
  const [support, setSupport] = useState(false);
  const [legal, setlegal] = useState(false);
  const [working, setworking] = useState(false);
  function formSubscription(e, subscribe) {
    e.preventDefault();
    let emailInput = e.currentTarget.children[0].value;
    subscribe({ emailInput });
  }
  //Toggle footer on item click
  function toggleExplore() {
    setExplore(!explore);
    setLinks(false);
    setSupport(false);
    setlegal(false);
    setworking(false);
  }
  function toggleLinks() {
    setExplore(false);
    setLinks(!links);
    setSupport(false);
    setlegal(false);
    setworking(false);
  }
  function toggleSupport() {
    setExplore(false);
    setLinks(false);
    setSupport(!support);
    setlegal(false);
    setworking(false);
  }
  function toggleLegal() {
    setExplore(false);
    setLinks(false);
    setSupport(false);
    setlegal(!legal);
    setworking(false);
  }
  function toggleWorking() {
    setExplore(false);
    setLinks(false);
    setSupport(false);
    setlegal(false);
    setworking(!working);
  }
  // let history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm(); // initialize the hook
  const [loader, setLoader] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [error, setError] = useState(false);
  const [closePopUp, setClosePopUp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  let hideTimeOut;
  const onSubmit = (data) => {
    setDisabled(true);
    setLoader(true);
    if (error === true) {
      setError(false);
    }
    // console.log(data);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/contactus`, data)
      .then((response) => {
        setLoader(false);
        // console.log(response)
        setSubmittingForm(true);
        hideTimeOut = setTimeout(() => hidePopUp(), 15000);
        // clearTimeOut=setTimeout(()=>setMessage({message:`${getLocale("Thank you!")}`}))
        reset({});
      })
      .catch((error) => {
        // console.log(error)
        setLoader(false);
        setError(true);
      });
  };

  const hidePopUp = () => {
    // history.push("/");
    setSubmittingForm(false);
    setDisabled(false);
  };
  useEffect(() => {
    if (submittingForm === false) {
      setClosePopUp(true);
    }
    clearTimeout(hideTimeOut);
  }, [submittingForm, hideTimeOut]);

  const openCookieSetting = () => {
    window.OneTrust.ToggleInfoDisplay();
  }
  return (
    <Fragment>
      {/* <Trip /> */}
      <footer>
        <Container className={styles.firstSection}>
          <Grid container justify="center">
            <Grid
              container
              item
              xs={10}
              justify="space-between"
              alignItems="center"
              className={`${styles.subscribe}`}
              direction={lang === "en" ? "row" : "row-reverse"}
            >
              <Grid item xs={12} md={5} className={`${styles.sectionHeader}`}>
                <p>{getLocale("Subscribe to newsletter")}</p>
                <span>{getLocale("stayupdated")}</span>
              </Grid>
              <MailchimpSubscribe
                url={process.env.REACT_APP_MAILCHIMP_URL}
                render={({ subscribe, status, message }) => (
                  <Grid
                    item
                    xs={12}
                    md={5}
                    className={`${styles.sectionEmailAddress}`}
                  >
                    <form
                      id="subscribe"
                      onSubmit={(e) => formSubscription(e, subscribe)}
                    >
                      <input
                        type="text"
                        name="EMAIL"
                        placeholder={`${lang === "en" ? "Email Address" : "البريد الألكتروني"
                          }`}
                      />
                      <button form="subscribe" type="submit">
                        {getLocale("submit")}
                      </button>
                    </form>
                    {status === "sending" && (
                      <div style={{ color: "blue" }}>sending...</div>
                    )}
                    {status === "error" && (
                      <div style={{ color: "red" }}>{message}</div>
                    )}
                    {status === "success" && (
                      <div style={{ color: "green" }}>Subscribed !</div>
                    )}
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </Container>
        <div className={`${styles.secondSection}`}>
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              direction={lang === "en" ? "row" : "row-reverse"}
            >
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.firstColumn}
              >
                <img src={logoSki} alt="logo" />
                <p>{getLocale("ultimatedestination")}</p>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  alignItems="center"
                  className={`${styles.sectionSocial}`}
                  spacing={1}
                  justify={lang === "ar" ? "center" : null}
                >
                  <Grid item xs={3}>
                    <a
                      href="https://www.facebook.com/skiegypt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>

                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href="https://www.instagram.com/ski.egypt/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href="https://www.youtube.com/channel/UCggXDtcjumJo_b_Rex5xnOg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </Grid>
                  <Grid item sx={3} >
                    <a
                      href="https://www.tiktok.com/@ski.egypt?is_copy_url=1&is_from_webapp=v1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTiktok} />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.secondColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={toggleExplore}
                  >
                    <p className={explore ? "chevron" : null}>
                      {getLocale("explore")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>

                  <Grid
                    container
                    className={`${styles.sectionData} ${!explore ? "collapse" : ""
                      }`}
                  >
                    <Grid item xs={12}>
                      <Link to={"/" + lang + "/"} rel="canonical">
                        {getLocale("home")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={"/" + lang + "/aboutus"} rel="canonical">
                        {getLocale("ABOUT")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={`/${lang}/skischool`}>
                        {getLocale("SKI SCHOOL")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={`/${lang}/penguins`}>
                        {getLocale("PENGUINS")}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.secondColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={toggleLinks}
                  >
                    <p className={links ? "chevron" : null}>
                      {getLocale("quicklinks")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>

                  <Grid
                    container
                    className={`${styles.sectionData} ${!links ? "collapse" : ""
                      }`}
                  >
                    <Grid item xs={12}>
                      <Link to={`/${lang}/offers`} rel="canonical">
                        {getLocale("OFFERS")}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={`/${lang}/prices`} rel="canonical">
                        {getLocale("PRICES")}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.thirdColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={toggleSupport}
                  >
                    <p className={support ? "chevron" : null}>
                      {getLocale("help")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>

                  <Grid
                    container
                    className={`${styles.sectionData} ${!support ? "collapse" : ""
                      }`}
                  >
                    <Grid item xs={12} className="privacyPolicy">
                      <a
                        href={`https://privacy-center-dev.majidalfuttaim.com/client/2d5739a0-dda3-4b4a-a445-c058ae6dd4eb?lang=${lang}&country=egy`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLocale("Privacy Center")}
                      </a>
                    </Grid>
                    <Grid item xs={12} className="privacyPolicy">
                      <a href={false} style={{ cursor: 'pointer' }} onClick={() => openCookieSetting()}>{getLocale('Cookie settings')}</a>
                    </Grid>
                    <Grid item xs={12}>
                      <Popup
                        hideClose
                        popupTitle=""
                        popupTriggerTitle={`${lang === "en" ? "FAQs" : "الأسئلة الاكثر شيوعًا"
                          }`}
                        popupTriggerClass="rules"
                      >
                        <div
                          className={`${lang === "en" ? "popupFaq" : "popupFaqAr"
                            }`}
                        >
                          <h2>{getLocale("FAQs")}</h2>
                          <PopupTab
                            question={getLocale("Q_FAQs1")}
                            answer={getLocale("A_FAQs1")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs2")}
                            answer={getLocale("A_FAQs2")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs3")}
                            answer={getLocale("A_FAQs3")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs4")}
                            answer={getLocale("A_FAQs4")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs5")}
                            answer={getLocale("A_FAQs5")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs6")}
                            answer={getLocale("A_FAQs6")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs7")}
                            answer={getLocale("A_FAQs7")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs8")}
                            answer={getLocale("A_FAQs8")}
                          />
                          <PopupTab
                            question={getLocale("Q_FAQs9")}
                            answer={getLocale("A_FAQs9")}
                          />
                        </div>
                      </Popup>
                    </Grid>
                    <Grid item xs={12}>
                      <Popup
                        dialogWidth="sm"
                        popupTitle={`${lang === "en" ? "Contact Us" : "تواصل معنا"
                          }`}
                        popupTriggerTitle={`${lang === "en" ? "Contact Us" : "تواصل معنا"
                          }`}
                        popupTriggerClass="contact"
                        onSubmit={closePopUp}
                        imgClose={flakeIcon}
                        closePopupStyle={`${lang === "en"
                          ? "closePopupStyleLEFT"
                          : "closePopupStyleRTL"
                          }`}
                      >
                        <div className="contactFooter">
                          <p
                            className={`${lang === "en" ? "LEFT" : "RTL"}`}
                          >{`${lang === "en"
                            ? "Happy to hear from you"
                            : "تواصل معنا"
                            }`}</p>
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={`${lang === "en" ? "LEFT" : "RTL"}`}
                          >
                            <Grid
                              container
                              justify="space-between"
                              className="form"
                            >
                              <Grid item xs={12}>
                                <input
                                  ref={register({ required: true })}
                                  name="first_name"
                                  type="text"
                                  placeholder={`${lang === "en"
                                    ? "*First Name"
                                    : "الأسم الأول*"
                                    }`}
                                />
                                {errors.first_name &&
                                  errors.first_name.type === "required" && (
                                    <div className="error">
                                      This field is required
                                    </div>
                                  )}
                              </Grid>
                              <Grid item xs={12} style={{ textAlign: "left" }}>
                                <input
                                  ref={register({ required: true })}
                                  name="last_name"
                                  type="text"
                                  placeholder={`${lang === "en"
                                    ? "*Last Name"
                                    : "الأسم الأخير*"
                                    }`}
                                />
                                {errors.last_name &&
                                  errors.last_name.type === "required" && (
                                    <div className="error">
                                      This field is required
                                    </div>
                                  )}
                              </Grid>
                              <Grid item xs={12}>
                                <input
                                  ref={register({ required: true })}
                                  name="phone"
                                  type="text"
                                  placeholder={`${lang === "en"
                                    ? "*Phone Number"
                                    : "رقم الهاتف*"
                                    }`}
                                />
                                {errors.phone &&
                                  errors.phone.type === "required" && (
                                    <div className="error">
                                      This field is required
                                    </div>
                                  )}
                              </Grid>
                              <Grid item xs={12} style={{ textAlign: "left" }}>
                                <input
                                  ref={register({ required: true })}
                                  name="email"
                                  type="email"
                                  placeholder={`${lang === "en"
                                    ? "*Email Address"
                                    : "البريد الألكتروني*"
                                    }`}
                                />
                                {errors.email &&
                                  errors.email.type === "required" && (
                                    <div className="error">
                                      This field is required
                                    </div>
                                  )}
                              </Grid>
                              <Grid item xs={12}>
                                <textarea
                                  name="message"
                                  placeholder={`${lang === "en" ? "*Message" : "الرسالة*"
                                    }`}
                                  ref={register({ required: true })}
                                />
                                {errors.message &&
                                  errors.message.type === "required" && (
                                    <div className="error">
                                      This field is required
                                    </div>
                                  )}
                              </Grid>
                            </Grid>
                            <Grid container justify="center">
                              <Grid
                                style={{ textAlign: "center" }}
                                item
                                xs={12}
                                className="containerBtnSubmit"
                              >
                                <button disabled={disabled} type="SUBMIT">{`${lang === "en" ? "Submit" : "ارسال"
                                  }`}</button>
                              </Grid>
                              {/* <Grid style={{ textAlign: "center" }} item xs={12} >

																<p className={"or"}>{getLocale("OR")}</p>
															</Grid> */}

                              {/* <Grid style={{ textAlign: "center" }} item xs={12} className="containerBtnSubmit">
																<div className="callUs-btn">
																	<a href="tel:16002" rel="noopener noreferrer">
																		{getLocale("CALL US")}
																	</a>
																</div>
															</Grid> */}
                              {loader ? (
                                <div
                                  className="loader"
                                  style={{
                                    height: "100vh",
                                    background: "#ffffff50",
                                    top: "0",
                                  }}
                                >
                                  <img src={loaderImg} alt="loader" />
                                </div>
                              ) : null}
                              {submittingForm && (
                                <Grid
                                  item
                                  xs={12}
                                  className="containerBtnSubmit"
                                >
                                  <div
                                    style={{ paddingTop: "0" }}
                                    className={"thankYou"}
                                  >
                                    {getLocale("Thank you!")}
                                  </div>
                                </Grid>
                              )}
                              {error && (
                                <Grid
                                  item
                                  xs={12}
                                  className="containerBtnSubmit"
                                >
                                  <div className={"errorOccurred"}>
                                    {getLocale("errorOccurred")}
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                          </form>
                        </div>
                      </Popup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.thirdColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={toggleLegal}
                  >
                    <p className={legal ? "chevron" : null}>
                      {getLocale("legal")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>

                  <Grid
                    container
                    className={`${styles.sectionData} ${!legal ? "collapse" : ""
                      }`}
                  >
                    {/* <Grid item xs={12} className="privacyPolicy">
                      <a
                        href="https://www.majidalfuttaim.com/en/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLocale("Privacy Policy")}
                      </a>
                    </Grid> */}
                    <Grid item xs={12} className="antiFraud">
                      <a
                        href="https://www.majidalfuttaim.com/en/anti-fraud-disclaimer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLocale("Anti-Fraud Disclaimer")}
                      </a>
                    </Grid>
                    <Grid item xs={12}>
                      <Popup
                        popupTitle=""
                        popupTriggerTitle={`${lang === "en"
                          ? "Terms & Conditions"
                          : "الشروط والأحكام"
                          }`}
                        popupTriggerClass="rules"
                      >
                        <div id="rulesPopup">
                          <Grid container item xs={12}>
                            <Grid item className="containerHeader">
                              <p>{`${lang === "en"
                                ? "Terms & Conditions"
                                : "الشروط والأحكام"
                                }`}</p>
                            </Grid>
                            <Grid item className="containerData">
                              <ul className="containerUL">
                                <Rules lang={lang} />
                              </ul>
                            </Grid>
                          </Grid>
                        </div>
                      </Popup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={2}
                alignItems="flex-start"
                className={styles.thirdColumn}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={`${styles.sectionFooterData}`}
                >
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionHeader}`}
                    onClick={toggleWorking}
                  >
                    <p className={working ? "chevron" : null}>
                      {getLocale("working hours")}
                      <img src={blueArrow} alt="arrow" />
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={`${styles.sectionData} ${!working ? "collapse" : null
                      }`}
                  >
                    <Grid container item xs={12}>
                      <Grid item>
                        <span>{getLocale("10 AM - 10 PM")}</span>
                        {/* <span style={{ fontWeight: "bold" }}>Weekdays: </span>
                        <span>10AM - 12AM</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Weekends:</span>
                        <span>10AM - 1AM</span>
                        <br />
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          Closure for Iftar:
                        </span>
                        <span>5PM - 7:30PM</span> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={`${styles.containercontainerCopyright}`}>
          <Container maxWidth="xl" className={`${styles.containerCopyright}`}>
            <Grid
              container
              spacing={1}
              direction={lang === "en" ? "row" : "row-reverse"}
            >
              <Grid container item xs={12} md={3} alignItems="center">
                <div className={`${styles.sectionBrandName}`}>
                  <img src={logo} alt="logo" />
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                alignItems="center"
                justify="center"
              >
                <div className={`${styles.sectionBrandTitle}`}>
                  <span>{getLocale("majid")}</span>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={4}
                alignItems="center"
                justify="center"
              >
                <div className={`${styles.sectionBrandTitle}`}>
                  <span>{getLocale("rightsreserved")}</span>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
