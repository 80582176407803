import { FETCH_DATA_IG } from '../actions/shared'
const initialValue = { data: [], next: null }

export default function ig(state = initialValue, action) {

    switch (action.type) {
        case FETCH_DATA_IG:
            return {
                data: [...state.data, ...(Array.isArray(action.data.data) ? action.data.data : [])],
                next: action.data.paging ? action.data.paging.next : null
            };
        default:
            return state
    }
}