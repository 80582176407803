import React, { useEffect, useState } from "react";
import styles from "./packages.module.scss";
// import Banner from "../General/headerPanel/headerPanel";
import BannerAbsolute from "../General/headerPanel2/headerPanel2";
// import packages from "../../img/packagesPanel.jpg";
// import HeaderText from "../offers/subOffers/HeaderText/HeaderText";
// import AlpineGrid from "../offers/subOffers/GridPackages/AlpineGrid";  To skiSchool
// import MembershipGrid from "../offers/subOffers/GridPackages/MembershipGrid";
// import SnowMuch from "./subComponents/snowMuch";
// import LittleSnow from "../offers/subOffers/littleSnow";
import Comparison from "./subComponents/comparison";
import useLocale from "../../lang/useLocale";
// import SocialSection from "../General/social-section/social-section";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import BannerVip from "../../img/banner_vip.png";
import BannerVipMobile from "../../img/banner_vip_mobile.png";
// import Package from './subComponents/package';

export default function Packages() {
  const lang = useSelector((state) => state.lang);
  const [homeData, setHomeData] = useState([]);
  const { getLocale } = useLocale();
  const dataPackages = useSelector((state) => state.webReducer);
  const [packagesData, setPackagesData] = useState([]);
  const { data } = dataPackages;

  useEffect(() => {
    if (Array.isArray(data)) {
      setHomeData(data[7]);
    }
  }, [data, homeData]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setPackagesData(data[3]);
    }
  }, [data, packagesData]);

  return (
    <div className={styles.packages}>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "Visit now ski Egypt to know prices & packages for enjoying time with family and friend. Visit now!"
          )}
        />
        <title>
          {getLocale("Visit Ski to Know Prices and Packages | SKI Egypt")}
        </title>
        <link rel="canonical" href={`https://skiegy.com/${lang}/prices`} />
      </Helmet>

      {/* <Banner
        panal={packagesData.image}
        alt="سكي مصر للاطفال"
        containerCard
        title={packagesData.title}
        description={packagesData.description}
      /> */}
      <BannerAbsolute
        panal={BannerVip}
        mobilePanel={BannerVipMobile}
        lang={lang}
        alt="سكي مصر للاطفال"
        containerCard
        title={packagesData.title}
        description={packagesData.description}
      />
      {/* <Package
        data={Array.isArray(homeData.sections) && homeData.sections[3]}
      /> */}
      <Comparison
        data={Array.isArray(packagesData.sections) && packagesData.sections[0]}
      />
      {/* <SnowMuch /> */}
      {/* <div className={styles.littleWrap}>
        <HeaderText
          title={getLocale("PACKAGES")}
          subtitle={getLocale("Little Snow")}
          description={getLocale("Little Snowexert")}
        />
        <LittleSnow
          data={
            Array.isArray(packagesData.sections) && packagesData.sections[1]
          }
        />
      </div> */}
      {/* <div className={styles.alpineWrap}>   to ski school
        <HeaderText
          title={getLocale("PACKAGES")}
          subtitle={getLocale("Alpine Packages")}
          description={getLocale("Bundled Ski School lessons with a discount")}
        />
        <AlpineGrid />
      </div> */}
      {/* <div className={styles.membershipWrap}>
        <HeaderText
          title={getLocale("Memberships")}
          subtitle={getLocale("Ski Fans Membership")}
          description={getLocale("Memberships for professional skiiers")}
        />
        <MembershipGrid />
      </div> */}
      {/* <SocialSection /> */}
    </div>
  );
}
