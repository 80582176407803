import React from "react";
import styles from "./headerPanel2.module.scss";
import UseLocale from "../../../lang/useLocale";
import { Container } from "@material-ui/core";
// import { Button } from "@material-ui/core";

const headerPanel = (props) => {
  const { getLocale } = UseLocale();

  return (
    <div className={styles.containerHeaderPanel} dir={getLocale("dir")}>
      {/* <div className={styles.bannerContainer}>
        <img
          className={styles.bannerImage}
          src={
            props.mobilePanel && window.innerWidth < 620
              ? props.mobilePanel
              : props.panal
          }
          alt="panal"
        />
        <Container maxWidth="lg">
          <div className={styles.bgContainer}>
            <div className={styles.bookContainer}>
              <div>
                <h2 className={styles.title}>{getLocale("snowpremium")}</h2>
                <h3 className={styles.price}>{getLocale("1500 EGP")}</h3>
                <div className={styles.breackLine} />
              </div>
              <ul>
                <li>{getLocale("Meet and Greet Santa")}</li>
                <li>{getLocale("Hot Chocolate (One Refill)")}</li>
                <li>{getLocale("Gift From Santa")}</li>
                <li>{getLocale("Digital Photo with Santa")}</li>
                <li>{getLocale("Unlimited access to Snow Park")}</li>
                <li>{getLocale("Discovery Lesson")}</li>
                <li>{getLocale("Penguin Encounter")}</li>
                <li>{getLocale("Meal voucher from Ski Egypt")}</li>
                <li>{getLocale("15% Discount on Snow Pro items")}</li>
              </ul>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.buttonStyle}
                  onClick={() =>
                    (window.location.href = `https://booking.skiegy.com/${props.lang}/slope/booking/vip-snow`)
                  }
                >
                  {getLocale("BOOK NOW")}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div> */}

      {props.containerCard ? (
        <Container maxWidth="lg">
          <div className={styles.containerTitel}>
            <p className={styles.subTitle}>{props.subTitle}</p>
            {!props.hideCardTitle ? (
              <h1 className={styles.title}>{props.title}</h1>
            ) : null}
            <p className={styles.description}>{props.description}</p>
          </div>
        </Container>
      ) : null}
    </div>
  );
};
export default headerPanel;
