import React, { useRef } from "react";
import styles from "../home.module.scss";
import Swiper from "../../General/swiper/swiper";
import { Grid } from "@material-ui/core";
// import packages1 from "../../../img/packages1.jpg";
// import packages2 from "../../../img/packages2.jpg";
// import packages3 from "../../../img/packages3.jpg";
// import hotDeals from "../../../img/hotDeals.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocale from "../../../lang/useLocale";
import homehead from "../../../../src/img/homehead.png";
import homefoot from "../../../../src/img/homefoot.png";

export default function Packages(props) {
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  // console.log(props.data);
  const settings = {
    // loop: true,
    spaceBetween: 10,
    arrows: true,
    bullets: false,
    autoplay: {
      delay: 5000,
    },
    autoplayDisableOnInteraction: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1025: {
        slidesPerView: 2,
      },
    },
    slidesPerView: "auto",
  };
  return (
    <div className={` ${styles.packages} ${styles.positionRelative}  ${styles.yourExperience} packages`} maxWidth="lg">
      {/* <h6 className={styles.title}>{props.data.title}</h6> */}

      <div className={`${styles.positionAbsoluteExp}`}>
        <img src={homehead} alt="snow" />
      </div>
      <h2 className={styles.subTitleNew}>{props.data.subtitle}</h2>
      <div className="packagesWrap">
        <Swiper settings={settings} mySwiper={mySwiper}>
          {props.data.plugin?.map((plug) => {
            return (
              <div
                key={plug.id}
                className={`${styles.packageSlide} swiper-slide`}
              >
                <div>
                  <div className={styles.cardImgWrap}>
                    <img src={plug.image} alt="packages1" />
                    <Grid
                      className={styles.cardImgContainer}
                      container
                      justify="space-between"
                      alignItems="flex-end"
                      direction={lang === "ar" ? "row-reverse" : "row"}
                    >
                      <Grid item xs={12} md={8}>
                        {/* <h4>SNOWBOARDING</h4> */}
                        <h2>{plug.name}</h2>
                        <p>{plug.description}</p>
                      </Grid>
                      < Grid item xs={12} md={4}>
                        {plug.availability === "Booking Unavailable" ? (
                          <button className={styles.bookUnavailable}>
                            {getLocale("Booking Unavailable")}
                          </button>) : (<a
                            href={plug.book}
                            rel="noopener noreferrer"
                            target="_self"
                            className={styles.book}
                          >
                            {getLocale("BOOK NOW")}
                          </a>)}
                        <Link
                          to={{
                            pathname: `/${lang}${props.data.url}`,
                            state: { moveTo: "tickets" },
                          }}
                          className={styles.view}
                          rel="canonical"
                        >
                          {getLocale("viewdetails")}
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className={`${styles.packageSlide} swiper-slide`}>
            <div>
              <div className={styles.cardImgWrap}>
                <img src={packages2} alt="packages1" />
                <Grid
                  className={styles.cardImgContainer}
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  direction={lang === "ar" ? "row-reverse" : "row"}
                >
                  <Grid item xs={12} md={8}> */}
          {/* <h4>SNOWBOARDING</h4> */}
          {/* <h2>{getLocale("snowplus")}</h2>
                    <p>{getLocale("snowplusexert2")}</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <a
                      // href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-plus`}
                      href={`https://booking.skiegy.com/${lang}/snowboard/booking/snow-plus-21`}
                      rel="noopener noreferrer"
                      target="_self"
                      className={styles.book}
                    >
                      {getLocale("BOOK NOW")}
                    </a>
                    <Link
                      to={{
                        pathname: `/${lang}/prices`,
                        state: { moveTo: "tickets" },
                      }}
                      className={styles.view}
                      rel="canonical"
                    >
                      {getLocale("viewdetails")}
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div> */}
          {/* <div className={`${styles.packageSlide} swiper-slide`}> */}
          {/* <img className= {styles.hotOffers} src={hotDeals} alt='Hot Offers'/> */}
          {/* <div>
							<div className={styles.cardImgWrap}>
								<img src={packages3} alt="packages1" />
								<Grid
									className={styles.cardImgContainer}
									container
									justify="space-between"
									alignItems="flex-end"
									direction={lang === "ar" ? "row-reverse" : "row"}
								>
									<Grid item xs={12} md={8}>
										<h2>{getLocale("meetpenguins")}</h2>
										<p>{getLocale("meetpenguinsexert")}</p>
									</Grid>
									<Grid item xs={12} md={4}>
										<a
											href={`https://booking.skiegy.com/${lang}/snow-penguins/booking/peng-friend-encounter`}
											target="_self"
											rel="noopener noreferrer"
											className={styles.book}
										>
											{getLocale("BOOK NOW")}
										</a>
										<Link
											to={{
												pathname: `/${lang}/penguins`,
												state: { moveTo: "meetPenguins" },
											}}
											className={styles.view}
										>
											{getLocale("viewdetails")}
										</Link>
									</Grid>
								</Grid>
							</div>
						</div>
					</div> */}
          {/* <div className={`${styles.packageSlide} swiper-slide`}>
            <div>
              <div className={styles.cardImgWrap}>
                <img src={packages2} alt="packages1" />
                <Grid
                  className={styles.cardImgContainer}
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  direction={lang === "ar" ? "row-reverse" : "row"}
                >
                  <Grid item xs={12} md={8}> */}
          {/* <h4>SNOWBOARDING</h4> */}
          {/* <h2>{getLocale("snowpremium")}</h2>
                    <p>{getLocale("snowpremiumexert")}</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <a
                      href={`https://booking.skiegy.com/${lang}/slope/booking/snow-premium`}
                      target="_self"
                      rel="noopener noreferrer"
                      className={styles.book}
                    >
                      {getLocale("BOOK NOW")}
                    </a>
                    <Link
                      to={{
                        pathname: `/${lang}/prices`,
                        state: { moveTo: "tickets" },
                      }}
                      className={styles.view}
                      rel="canonical"
                    >
                      {getLocale("viewdetails")}
                    </Link>
                  </Grid> */}
          {/* </Grid>
              </div>
            </div> */}
          {/* </div> */}
        </Swiper>
      </div>
      <div className={`${styles.positionAbsoluteFootExp}`}>
        <img src={homefoot} alt="snow" />
      </div>
    </div>
  );
}
